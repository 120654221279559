import * as React from "react"
import tw from "twin.macro"
import Emblem from "../images/emblem.svg"

const HeaderRoot = tw.header`absolute p-5 w-full`
const HeaderInner = tw.div`flex items-center justify-center`
const HeaderBrand = tw.img`h-[60px] w-[60px]`

const Header = ({ siteTitle }) => {
  return (
    <HeaderRoot>
      <HeaderInner>
        <a href="https://www.chinared.gg/" target="_blank" rel="noreferrer">
          <HeaderBrand src={Emblem} alt={siteTitle} title={siteTitle} />
        </a>
      </HeaderInner>
    </HeaderRoot>
  )
}

export default Header
