import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import tw, { styled, css } from "twin.macro"
import Cover from "../images/cover.jpg"

const IndexPage = () => {
  const Section = styled.section(
    tw`min-h-screen pb-5 bg-center bg-cover pt-36 md:pt-48`,
    css`
      background-image: URL(${Cover});
    `
  )
  const Container = tw.div`container !max-w-[732px]`
  const Symbol = tw.span`block mb-4 text-center text-xl md:(text-2xl) text-gold`
  const Content = tw.p`leading-tight max-w-[520px] mb-4 last:(mb-0) mx-auto text-center`
  const Widget = tw.iframe`border-0 h-[800px] max-w-[692px] mb-24 mt-8 mx-auto w-full`
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book | China Red</title>
        <meta
          name="description"
          content="Make your reservation at China Red using our ResDiary&trade; form. If your party size is larger than eight people, please contact the restaurant to discuss your requirements. Reservations placed through Facebook Messenger will not be processed."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section>
        <Container>
          <Symbol>预订</Symbol>
          <h1>Book</h1>
          <Content>Make your reservation at China Red using our ResDiary&trade; form.</Content>
          <Content css={tw`mb-3 uppercase`}>
            If your party size is larger than eight people, please call the restaurant on{" "}
            <a href="tel:+441481723888" css={tw`font-bold`}>
              723888
            </a>{" "}
            to discuss your requirements.
          </Content>
          <Content>
            Reservations of more than eight people placed through this form will not be processed.
            Reservations placed through Facebook Messenger will not be processed.
          </Content>
          <Widget
            src="https://booking.resdiary.com/widget/Standard/ChinaRed/28858?"
            allowtransparency="true"
            frameborder="0"
          ></Widget>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
